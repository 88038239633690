<template>
<div id="app">

    <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
        <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
            <v-layout column>
                <div>
                    <strong>{{ snackbartest.title }}</strong>
                </div>
                <div>{{ snackbartest.text }}</div>
            </v-layout>
        </v-layout>
        <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
            <v-icon>clear</v-icon>
        </v-btn>
    </v-snackbar>

    <v-card  outlined>
        <v-list-item three-line>
            <v-list-item-content>

                <v-list-item-title class="text-h5 mb-2">
                    Reference du projet : {{ this.reference_matricule }}
                </v-list-item-title>
                <v-list-item-subtitle>Detail : {{ this.information }}</v-list-item-subtitle>
            </v-list-item-content>

        </v-list-item>
        <v-row>
            <v-select :items="listpays" v-model="selectpays" dense @change="fuseSearchdepartement" item-value="id" outlined label="Pays">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                    </v-select>

                                    <v-select :items="listdepartement" v-model="selectdepartement" dense @change="fuseSearchcommune" item-value="id" outlined label="Département">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                    </v-select>

                                    <v-select :items="listcommune" v-model="selectcommune" dense @change="fuseSearcharrondissement" item-value="id" outlined label="Commune *">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                    </v-select>

                                    <v-select :items="listarrondissement" v-model="selectarrondissement" dense @change="fuseSearchvillage" item-value="id" outlined label="Arrondissement *">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                    </v-select>

                                    <v-select :items="listvillage" v-model="selectvillage" dense item-value="id" outlined label="Village *">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                    </v-select>

        </v-row>

    </v-card>
    <v-dialog v-model="dialogmatricule" max-width="500px">

        <v-card>
            <v-card-title>
                <span class="text-h5">Formulaire de verification</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field v-model="matricule" label="Reference du souscription*" dense outlined required></v-text-field>
                    </v-form>

                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialogmatricule=false">
                    Fermer
                </v-btn>
                <v-btn color="green darken-1" text @click="this.read_matricule_All">
                    Valider
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">

        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Liste des collectes données niveau village</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">

                    <v-snackbar v-model="snackbar" :multi-line="multiLine">
                        {{ text }}

                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                Fermer
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                            Nouveau
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-select :items="listpays" v-model="selectpays" dense @change="fuseSearchdepartement" item-value="id" outlined label="Pays">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                    </v-select>

                                    <v-select :items="listdepartement" v-model="selectdepartement" dense @change="fuseSearchcommune" item-value="id" outlined label="Département">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                    </v-select>

                                    <v-select :items="listcommune" v-model="selectcommune" dense @change="fuseSearcharrondissement" item-value="id" outlined label="Commune *">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                    </v-select>

                                    <v-select :items="listarrondissement" v-model="selectarrondissement" dense @change="fuseSearchvillage" item-value="id" outlined label="Arrondissement *">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                    </v-select>

                                    <v-select :items="listvillage" v-model="selectvillage" dense item-value="id" outlined label="Village *">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                    </v-select>

                                    <v-select :items="listmodalite" v-model="selectmodalite" dense item-value="id" outlined label="Modalité *">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                    </v-select>

                                    <v-text-field v-model="libelle" label="La valeur*" dense outlined required></v-text-field>

                                    <v-snackbar v-model="snackbar" :multi-line="multiLine">
                                        {{ text }}

                                        <template v-slot:action="{ attrs }">
                                            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                                Fermer
                                            </v-btn>
                                        </template>
                                    </v-snackbar>
                                </v-form>

                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">
                                Fermer
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                                Enregistrer
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:[`item.souscriptions.indicateurodd_extrat`]="{ item }">
            {{ item.souscriptions.indicateurodd_extrat.code }} - {{ item.souscriptions.indicateurodd_extrat.libelle }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" color="green" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon color="red" class="mr-2" small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
            <v-icon color="bleu" class="mr-2" small @click="detail_collecte()">
                mdi-database-sync
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Actualiser la liste
            </v-btn>
        </template>
    </v-data-table>

</div>
</template>

<script>
import api from '../../../../serviceApi/apiService'
//import info_collecte_oddVue from './info_collecte_odd.vue'

export default {
    props: {
        //id: "0",
        /// idinfo_collecte: "0",
        annee: '',
        niveau: '',
        annee: '',
        informationData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        multiLine: true,
        snackbartest: false,
        textmessage: '',

        snackbar: false,
        snackbars: false,
        text: `message`,
        textsnackbar: 'messages',
        dialog: false,
        dialogDelete: false,
        dialogmatricule: false,
        headers: [{
                text: 'Actions',
                value: 'actions',
                sortable: false,
                divider: true,
                width: '50px'
            },
            {
                text: 'ID',
                value: 'id',
                divider: true,
                width: '10px'
            },
            {
                text: 'Annee',
                value: 'collectes.annee_collecte',
                divider: true,
                width: '20px'
            },
            {
                text: 'Modalité',
                value: 'modalites.libelle',
                divider: true,
                width: '80px'
            },
            {
                text: 'valeur',
                align: 'start',
                value: 'valeur',
                divider: true,
                width: '10px'
            },
            {
                text: 'village',
                align: 'start',
                sortable: false,
                value: 'villages.libelle',
                divider: true,
                width: '150px'
            },
            {
                text: 'Arrondissement',
                align: 'start',
                sortable: false,
                value: 'villages.arrondissements.libelle',
                divider: true,
                width: '100px'
            },
            {
                text: 'Commune',
                align: 'start',
                sortable: false,
                value: 'villages.arrondissements.communes.libelle',
                divider: true,
                width: '100px'
            },
            {
                text: 'Departement',
                align: 'start',
                sortable: false,
                value: 'villages.arrondissements.communes.departements.libelle',
                divider: true,
                width: '100px'
            },

        ],

        formTitle: 'Formulaire de collecte',
        list: [],
        listpays: [],

        listdepartement: [],
        listdepartement2: [],
        listdepartement3: [],
        listcommune: [],
        listarrondissement: [],
        listvillage: [],

        listcommune2: [],
        listarrondissement2: [],
        listvillage2: [],

        listmodalite: [],

        selectpays: '',

        selectdepartement: '',
        selectcommune: '',
        selectarrondissement: '',
        selectvillage: '',
        selectmodalite: "",
        idvillage: "0",
        idmodalite: "0",

        idindicateur: '0',

        selectcible: '',
        ref_projet: '',
        observation: '',
        valeur: '',
        valider: '0',
        selectannee: '',
        valid: true,
        dates: '',
        editedIndex: -1,
        textetat: false,
        dateFormatted: null,
        messages: '',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        //dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

        menu1: false,

        reference_matricule: '',
        information: '',
        idinfo_collecte: '0',

    }),

    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date)
        },
    },

    watch: {
        date(val) {
            this.dateFormatted = this.formatDate(this.date)
        },
    },
    /*created() {
        this.initialize()
    },*/
    methods: {
        //=====Affichages  liste des odds==========
        readAll: async function () {

            const data = await api.readAll('info-collecte-data/all-request-ligne-collecte/' + this.$route.params.idcollectes);
            this.list = data.list;

        },
        readGlobaleAll: async function () {
            const data = await api.readAll('parametre/all-globale-geo');
            //console.log("data");
            this.listpays = data.pays;
            //this.listdepartement = data.departement;
            this.listdepartement3 = data.departement;
            this.listcommune2 = data.commune;
            this.listarrondissement2 = data.arrondissement;
            this.listvillage2 = data.village;
        },

        readModaliteAll: async function () {
            const data = await api.readAll('parametre/all-modalite-odd-extrat/'+this.$route.params.id_indicateur_extrat);
            //console.log(data);
            this.listmodalite = data.list;
        },
        read_matricule_All: async function () {
            // const data = await api.readAll('souscription/verifie-souscription-odd-extrat');
            let fd = new FormData();
            fd.append('code', this.matricule);
            const data = await api.createUpdatedata('info-collecte-data/verifie-souscription', fd);
            this.list = data.list;
            this.listinfo = data.list_info;
            if (data.status == 200) {
                this.information = this.listinfo[0].indicateurodd_extrat.code + ' - ' + this.listinfo[0].indicateurodd_extrat.libelle;
                this.reference_matricule = this.listinfo[0].code;
                this.dialogmatricule = false;
            } else {
                this.reference_matricule = 'xxxxxxx';
                this.information = 'xxxxxxxxx';
            }

        },
        detail_collecte() {
            this.$router.push('operation-collecte-odd');
        },
        deleteData: async function () {
            let fd = new FormData();
            fd.append('id', this.id_odds);
            const res = await api.createUpdatedata('souscription/delete', fd);
            this.messagesligne(res);
        },

        createUpdatedata: async function () {
            this.idinfo_collecte = "1";
            
            let fd = new FormData();
            fd.append('valeur', this.libelle);
            fd.append('id_modalites', this.selectmodalite);
            fd.append('niveau', 'village');
            fd.append('id_niveau', this.selectvillage);
            fd.append('id_collectes', this.$route.params.idcollectes);

            if (this.textetat) {

                const res = await api.createUpdatedata('info-collecte-data/update-request-ligne-collecte/' + this.id, fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    }
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "red",
                        icon: "mdi-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    }
                }

            } else {
                const res = await api.createUpdatedata('info-collecte-data/add-request-ligne-collecte', fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    }
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "red",
                        icon: "mdi-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    }
                }
            }

            this.clear();
            this.readAll();
        },
        initialize() {
            this.readAll();
        },

        nouveau() {
            this.textetat = false;

        },

        clear() {
            this.textetat = false;
            this.observation = '';

        },
        deleteItem(item) {
            this.id_odds = item.id;
            this.dialogDelete = true;

        },
        editItem(item) {
            this.id = item.id;
            this.reference_matricule = item.souscriptions.code;
            this.selectannee = item.annee_collecte;
            this.dates = item.dates;
            this.textetat = true;
            this.dialog = true;
        },

        chargeItem(item) {
            this.idindicateur = item.souscriptions.indicateurodd.id;
            this.$emit('libelle_indicateur', "Indicateur : " + item.souscriptions.indicateurodd.code + "-" + item.souscriptions.indicateurodd.libelle);
            this.$emit('reference_projet', "Reference Projet : " + item.souscriptions.code);
            this.$emit('idcollecte', item.id);
            this.$emit('idindicateur', item.souscriptions.indicateurodd.id);
            this.fuseSearchmodalite();
            this.$emit('listemodalite', this.listmodalite);
            // console.log(this.listmodalite);

        },
        /* fuseSearchmodalite() {
             this.listmodalite = [];
             this.listmodalite = this.listmodalite2.filter(this.parcouremodalite);
         },*/
        parcouremodalite: function (obj) {
            if (obj.id_indicateurodds == this.idindicateur) {
                return true;
            } else {
                return false;
            }
        },

        getColor(validation) {
            if (validation = "0") return 'red'
            else if (calories = '1') return 'green'
            else return 'red'
        },

        getvalidation(validation) {
            if (validation = "0") return 'NON'
            else if (calories = '1') return 'OUI'
            else return 'NON'
        },

        deleteItemConfirm() {
            this.deleteData();
            this.readAll();
            this.dialogDelete = false;
        },

        save() {
            /*if (this.reference_matricule == '') {
                this.text = "Veuillez Inserer la reference de souscription";
                this.snackbar = true;
                return;
            }*/

            this.createUpdatedata();

        },

        parcoure: function filtrerParID(obj) {
            if (obj.id_cibles == this.selectcible) {
                console.log(obj.libelle);
                return true;
            } else {

                return false;
            }
        },

        fuseSearchmodalite() {
            this.listmodalite = [];
            this.listmodalite = this.listmodalite2.filter(this.parcouremodalite);
        },

        fuseSearchdepartement() {
            this.listdepartement = [];
            this.listcommune = [];
            this.listarrondissement = [];
            this.listvillage = [];
            this.listdepartement = this.listdepartement3.filter(this.parcouredepartement);

        },

        fuseSearchcommune() {
            this.listcommune = [];
            this.listarrondissement = [];
            this.listvillage = [];
            this.listcommune = this.listcommune2.filter(this.parcourecommune);
        },

        fuseSearcharrondissement() {
            this.listarrondissement = [];
            this.listvillage = [];
            this.listarrondissement = this.listarrondissement2.filter(this.parcourearrondissement);
        },

        fuseSearchvillage() {
            this.listvillage = [];
            this.listvillage = this.listvillage2.filter(this.parcourevillage);
        },

        parcouremodalite: function (obj) {
            if (obj.id_indicateurodds == this.idindicateur) {
                return true;
            } else {
                return false;
            }
        },

        parcouredepartement: function (obj) {
            if (obj.id_pays == this.selectpays) {
                return true;
            } else {
                return false;
            }
        },

        parcourecommune: function (obj) {
            if (obj.id_departements == this.selectdepartement) {
                return true;
            } else {
                return false;
            }
        },

        parcourearrondissement: function (obj) {
            if (obj.id_communes == this.selectcommune) {
                return true;
            } else {
                return false;
            }
        },

        parcourevillage: function (obj) {
            if (obj.id_arrondissements == this.selectarrondissement) {
                return true;
            } else {
                return false;
            }
        },

    },
    mounted() {
        this.readAll();
        this.readGlobaleAll();
        this.readModaliteAll();
    },

}
</script>
